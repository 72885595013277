import { FC, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import cn from 'classnames';
// import ReCAPTCHA from 'react-google-recaptcha';
import Median from 'median-js-bridge';

import { useLoadingBars } from '../../../components/LoadingBar/LoadingBar';

import {
  useAppDispatch,
  // useAppSelector,
  useUrlTrack,
} from '../../../services/hooks';
// import { getViewMode } from '../../../services/reducers/sharedReducer';
import { signin } from '../../../apis/users';
import { userSignin } from '../../../services/reducers/userReducer';
import { PASSWORD_MIN_LENGTH } from '../../../config/config';
// import { API_RECAPTCHA_KEY } from '../../../config/config';
import { getServerImageUrl, getStringByLanguage } from '../../../utils/utils';

import styles from './styles.module.scss';

const Signin: FC = () => {
  const dispatch = useAppDispatch();
  const urlInfo = useUrlTrack();
  const [UrlSearchParams] = useSearchParams();
  // const mode = useAppSelector(getViewMode);

  const [showLoadingBar, hideLoadingBar] = useLoadingBars();
  // const recaptchaRef = useRef<ReCAPTCHA>(null);

  const loginUserSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
        getStringByLanguage('INVALID_EMAIL_ADDRESS'),
      )
      .required(getStringByLanguage('ENTER_EMAIL_ADDRESS')),
    password: Yup.string()
      .min(PASSWORD_MIN_LENGTH)
      .required(getStringByLanguage('ENTER_PASSWORD')),
    // recaptcha: Yup.string().required('reCAPTCHA is required'),
  });

  // const isMobileDevice = () => {
  //   return /Mobi|Android/i.test(navigator.userAgent);
  // };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      recaptcha: '',
      remember: false,
      push_token: '' as string | number | boolean,
    },
    enableReinitialize: true,
    validationSchema: loginUserSchema,
    onSubmit: async (values) => {
      showLoadingBar();
      try {
        // if (isMobileDevice()) {
        //   const oneSignalInfo = await Median.onesignal.onesignalInfo();
        //   values.push_token = oneSignalInfo.oneSignalPushToken ?? null;
        // }
        const { data } = await signin(values);
        if (data.data) {
          // await Median.onesignal.login(values.email);
          dispatch(userSignin(data.data));
          const redirect_url = UrlSearchParams.get('redirect_url');
          if (redirect_url) {
            window.location.replace(redirect_url);
          }
        }
      } catch (error) {
        // if (recaptchaRef.current) {
        //   recaptchaRef.current.reset();
        // }
        console.log(error);
      }
      hideLoadingBar();
    },
  });

  useEffect(() => {
    console.log('gg');
    Median.onReady(async () => {
      console.log('ggg');
      const oneSignalInfo = await Median.onesignal.onesignalInfo();
      formik.setFieldValue(
        'push_token',
        oneSignalInfo.oneSignalPushToken ?? null,
      );
    });
  }, []);

  const onLogin = async () => {
    formik.handleSubmit();
  };

  return (
    <FormikProvider value={formik}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <div
          className={styles['kut-signin-body']}
          style={{
            backgroundImage: `url('${getServerImageUrl(
              urlInfo.auth_background,
            )}')`,
          }}
        >
          <div className={cn([styles['kut-signin']])}>
            <div className={styles['kut-signin-form']}>
              <div className={styles['kut-signin-logo']}>
                <img
                  alt="wesmart logo"
                  src={getServerImageUrl(urlInfo.logo)}
                  width={265}
                />
              </div>
              <div className={styles['kut-signin-content']}>
                <div className={styles['kut-signin-credentials']}>
                  <input
                    className={styles['kut-signin-email']}
                    type="text"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    placeholder={getStringByLanguage('_EMAIL')}
                  />
                  <input
                    className={styles['kut-signin-password']}
                    type="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    placeholder={getStringByLanguage('_PASSWORD')}
                  />
                  {/* <ReCAPTCHA
                    ref={recaptchaRef}
                    theme={mode}
                    sitekey={API_RECAPTCHA_KEY}
                    onChange={(value) => {
                      formik.setFieldValue('recaptcha', value);
                    }}
                    style={{ paddingLeft: '6%' }}
                  /> */}
                </div>
                <div className={styles['kut-signin-actions']}>
                  <p
                    className={cn([
                      styles['kut-signin-error'],
                      (formik.touched.email && formik.errors.email) ||
                      (formik.touched.password && formik.errors.password) ||
                      (formik.touched.recaptcha && formik.errors.recaptcha)
                        ? styles['show']
                        : '',
                    ])}
                  >
                    {(formik.touched.email && formik.errors.email) ||
                      (formik.touched.password && formik.errors.password) ||
                      (formik.touched.recaptcha && formik.errors.recaptcha)}
                  </p>
                  <span
                    className={styles['kut-signin-button']}
                    onClick={onLogin}
                  >
                    Login now
                  </span>
                  <div className={styles['kut-signin-remember-me']}>
                    <input
                      type="checkbox"
                      id="remember"
                      name="remember"
                      checked={formik.values.remember}
                      onChange={formik.handleChange}
                    />
                    <label htmlFor="remember">Remember me for 30 days</label>
                  </div>
                  <Link
                    className={styles['kut-signin-forgot-password']}
                    to="/reset-password"
                  >
                    Forgot Password?
                  </Link>
                </div>
              </div>
            </div>
            <div className={styles['kut-signin-footer']}>
              {urlInfo.description}
            </div>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

export default Signin;
